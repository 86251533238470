import React, { useState, useEffect } from "react"
import { graphql } from "gatsby"
import Seo from "../../components/common/seo"
import Layout from "../../components/common/layout"
import Modal from "react-modal"
import Hero from "../../components/members-zone/hero.js"

import DownloadImg from "../../../static/assets/image/Data-Suite-Download-button-120x115.jpg"
import setupImg1 from "../../../static/assets/image/setupImg1.png"
import setupImg2 from "../../../static/assets/image/setupImg2.png"
import setupImg3 from "../../../static/assets/image/setupImg3.png"
import setupImg4 from "../../../static/assets/image/setupImg4.png"
import setupImg5 from "../../../static/assets/image/setupImg5.png"
import setupImg6 from "../../../static/assets/image/setupImg6.png"
import setupImg7 from "../../../static/assets/image/setupImg7.png"
// import setupImg8 from "../static/assets/image/setupImg8.png"
import updateImg1 from "../../../static/assets/image/setupImg1.png"
import updateImg2 from "../../../static/assets/image/setupImg2.png"
import updateImg3 from "../../../static/assets/image/setupImg3.png"
import updateImg4 from "../../../static/assets/image/setupImg4.png"
import updateImg5 from "../../../static/assets/image/setupImg5.png"

const MembersZone = ({ data }) => {

  const [modalIsOpen, setIsOpen] = useState(false)
  function openModal() {
    setIsOpen(true)
  }
  function closeModal() {
    setIsOpen(false)
  }
    
 const [modalIsOpenTwo, setIsOpenTwo] = useState(false)
  function openModalTwo() {
    setIsOpenTwo(true)
  }
  function closeModalTwo() {
    setIsOpenTwo(false)
  }
  
  const [modalIsOpenThree, setIsOpenThree] = useState(false)
  function openModalThree() {
    setIsOpenThree(true)
  }
  function closeModalThree() {
    setIsOpenThree(false)
  }
    
const [modalIsOpenFour, setIsOpenFour] = useState(false)
  function openModalFour() {
    setIsOpenFour(true)
  }
  function closeModalFour() {
    setIsOpenFour(false)
  }

const [modalIsOpenFive, setIsOpenFive] = useState(false)
  function openModalFive() {
    setIsOpenFive(true)
  }
  function closeModalFive() {
    setIsOpenFive(false)
  }
    
const [modalIsOpenSix, setIsOpenSix] = useState(false)
  function openModalSix() {
    setIsOpenSix(true)
  }
  function closeModalSix() {
    setIsOpenSix(false)
  }
    
const [modalIsOpenSeven, setIsOpenSeven] = useState(false)
  function openModalSeven() {
    setIsOpenSeven(true)
  }
  function closeModalSeven() {
    setIsOpenSeven(false)
  }
  
const [modalIsOpenEight, setIsOpenEight] = useState(false)
  function openModalEight() {
    setIsOpenEight(true)
  }
  function closeModalEight() {
    setIsOpenEight(false)
  }    

const [modalIsOpenNine, setIsOpenNine] = useState(false)
  function openModalNine() {
    setIsOpenNine(true)
  }
  function closeModalNine() {
    setIsOpenNine(false)
  }  
  
  const [modalIsOpenTen, setIsOpenTen] = useState(false)
  function openModalTen() {
    setIsOpenTen(true)
  }
  function closeModalTen() {
    setIsOpenTen(false)
  }  
  
  const [modalIsOpenEleven, setIsOpenEleven] = useState(false)
  function openModalEleven() {
    setIsOpenEleven(true)
  }
  function closeModalEleven() {
    setIsOpenEleven(false)
  }  
  
  const [modalIsOpenTwelve, setIsOpenTwelve] = useState(false)
  function openModalTwelve() {
    setIsOpenTwelve(true)
  }
  function closeModalTwelve() {
    setIsOpenTwelve(false)
  }   
  
 const [modalIsOpenThirteen, setIsOpenThirteen] = useState(false)
  function openModalThirteen() {
    setIsOpenThirteen(true)
  }
  function closeModalThirteen() {
    setIsOpenThirteen(false)
  } 
  
const [modalIsOpenFourteen, setIsOpenFourteen] = useState(false)
  function openModalFourteen() {
    setIsOpenFourteen(true)
  }
  function closeModalFourteen() {
    setIsOpenFourteen(false)
} 

const [modalIsOpenFifteen, setIsOpenFifteen] = useState(false)
  function openModalFifteen() {
    setIsOpenFifteen(true)
  }
  function closeModalFifteen() {
    setIsOpenFifteen(false)
    } 
    
const [modalIsOpenSixteen, setIsOpenSixteen] = useState(false)
  function openModalSixteen() {
    setIsOpenSixteen(true)
  }
  function closeModalSixteen() {
    setIsOpenSixteen(false)
} 

const [modalIsOpenSeventeen, setIsOpenSeventeen] = useState(false)
  function openModalSeventeen() {
    setIsOpenSeventeen(true)
  }
  function closeModalSeventeen() {
    setIsOpenSeventeen(false)
} 

  
        useEffect(() => {
      if (window.location.href.indexOf("fr") > -1) {
        setTimeout(() => {
        document.querySelector("body").classList.add("fr");
      }, 500)
        // alert("hello")
      }
      }, [])

    
  Modal.setAppElement("#___gatsby")

    return (
        <div className="membersZone">
            <Layout>
                <Seo title="Members Zone" />
                <Hero />
                <div className="membersZoneWrapper soloPage indMember fr-only">
                     <h2>NavigateurCOMMB</h2>
                        <p className="lead">Nous vous recommandons de visionner cette vidéo en mode plein écran, à une résolution de 720p. Pour faire apparaître le menu des réglages, placez votre curseur au bas de l’image.</p>
                        <hr />
                        <br />
                        <div className="videosWrapper">
                            <div>
                                <h3>Vidéo 1</h3>
                                <p>Se familiariser avec l’application et comment s’y retrouver rapidement</p>
                                <iframe width="560" height="315" src="//www.youtube.com/embed/Op1vhUq2jtM?list=PLd6263jKVNWGEjy5dFQw1r9wnWYyk0S0Y" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                            </div>
                            <div>
                                <h3>Vidéo 2</h3>
                                <p>Utiliser les ressources de planification (Plan Builder) pour créer un plan simple</p>
                                <iframe width="560" height="315" src="https://www.youtube.com/embed/Q6YSy8_Ind8?list=PLd6263jKVNWGEjy5dFQw1r9wnWYyk0S0Y" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                            </div>
                            <div>
                                <h3>Vidéo 3</h3>
                                <p>Utiliser les ressources de planification (Plan Builder) pour comparer différents scénarios de campagne</p>
                                <iframe width="560" height="315" src="https://www.youtube.com/embed/47667jqBN3Q" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                            </div>
                            <div>
                                <h3>Vidéo 4</h3>
                                <p>Ciblage: Comment choisir une cible simple et construire une cible combinée</p>
                                <iframe width="560" height="315" src="https://www.youtube.com/embed/HqiitBT8kXM" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                            </div>
                            <div>
                                <h3>Vidéo 5</h3>
                                <p>Comment définir et sauvegarder le modèle de vos rapports (en anglais seulement)</p>
                                <iframe width="560" height="315" src="https://www.youtube.com/embed/KUW37B0zmRs" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                            </div>
                            <div>
                                <h3>Vidéo 6</h3>
                                <p>Comment vérifier et finaliser les détails d’un rapport (en anglais seulement)</p>
                                <iframe width="560" height="315" src="https://www.youtube.com/embed/HUamx1CwfvM" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                            </div>
                            <div>
                                <h3>Vidéo 7</h3>
                                <p>Options de sauvegarde (en anglais seulement)</p>
                                <iframe width="560" height="315" src="https://www.youtube.com/embed/2nAiXbb9nzk" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                            </div>
                            <div>
                                <h3>Vidéo 8</h3>
                                <p>Comment utiliser le module de planification par vagues</p>
                                <iframe width="560" height="315" src="//www.youtube.com/embed/emcCKeEqqmA?list=PLd6263jKVNWGEjy5dFQw1r9wnWYyk0S0Y" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                            </div>
                            <div>
                                <h3>Vidéo 9</h3>
                                <p>Comment présenter les résultats sous forme de graphique</p>
                                <iframe width="560" height="315" src="//www.youtube.com/embed/sWURqjhVkSY?list=PLd6263jKVNWGEjy5dFQw1r9wnWYyk0S0Y" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>                  
                            </div>
                        </div>
                </div>
                <div className="membersZoneWrapper soloPage indMember en-only">
                     <h2>COMMBNavigator® Video Tutorials </h2>
                        <p className="lead">These videos are best viewed at 720p resolution and full screen. Place your cursor at the bottom of the video screen to access these menu selections.</p>
                        <hr />
                        <br />
                        <div className="videosWrapper">
                            <div>
                                <h3>Video 1</h3>
                                <p>Understanding the basics; how to get around</p>
                                <iframe width="560" height="315" src="https://www.youtube.com/embed/7pbWQpaW72U" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                            </div>
                            <div>
                                <h3>Video 2</h3>
                                <p>Using the Plan Builder to construct simple plans</p>
                                <iframe width="560" height="315" src="https://www.youtube.com/embed/PucnA7w9qFo" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                            </div>
                            <div>
                                <h3>Video 3</h3>
                                <p>Using the Plan Builder to compare multi-scenario plans</p>
                                <iframe width="560" height="315" src="https://www.youtube.com/embed/cWWss2x5FyQ" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                            </div>
                            <div>
                                <h3>Video 4</h3>
                                <p>How to select a basic target & create a combined target</p>
                                <iframe width="560" height="315" src="https://www.youtube.com/embed/8T_kV2ygi8o" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                            </div>
                            <div>
                                <h3>Video 5</h3>
                                <p>How to build and save your own report layouts</p>
                                <iframe width="560" height="315" src="https://www.youtube.com/embed/KUW37B0zmRs" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                            </div>
                            <div>
                                <h3>Video 6</h3>
                                <p>How to review and finalize report details</p>
                                <iframe width="560" height="315" src="https://www.youtube.com/embed/HUamx1CwfvM" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                            </div>
                            <div>
                                <h3>Video 7</h3>
                                <p>Saving options</p>
                                <iframe width="560" height="315" src="https://www.youtube.com/embed/2nAiXbb9nzk" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                            </div>
                            <div>
                                <h3>Video 8</h3>
                                <p>How to use the Flighting option</p>
                                <iframe width="560" height="315" src="https://www.youtube.com/embed/2tcwsDNbt6g" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                            </div>
                            <div>
                                <h3>Video 9</h3>
                                <p>How to build graphs</p>
                                <iframe width="560" height="315" src="https://www.youtube.com/embed/rEjcqs7KQ_A" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>                  
                            </div>
                        </div>
                </div>
            </Layout>
        </div>
    )
}

export default MembersZone